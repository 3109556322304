<template>
    <v-card>
        <v-data-table :headers="headers" :loading="loadingEntries" loading-text="Loading... Please wait" :items="entriesLocal" disable-pagination :hide-default-footer="true">        
        <template v-slot:body="{items}">
            <tbody>
                <tr v-for="(item, index) in items" :key="index" :class="item.animateIn ? 'animate-in': ''">
                    <td> <v-icon class="py-3" large> mdi-{{item.thumb}}</v-icon></td>
                    <td>{{item.name}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.address}}</td>
                    <td>{{item.sample_date}}</td>
                    <td>{{item.test_number}}</td>
                    <td>{{item.sample_result}}</td>
                    <td>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2" color="primary" outlined fab small elevation="0" v-bind="attrs" v-on="on" @click="prefillPrivateEntryForm(item)">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Prefill entry form with this subject's data</span>
                        </v-tooltip>
                    </td>
                </tr>
            </tbody>
        </template>
        </v-data-table>
        <v-progress-linear indeterminate color="primary" v-if="infinScroll.loadingMore" />
        <template v-if="loadingEntries && entriesLocal.length == 0">
            <v-skeleton-loader 
                v-for="(page,i) in 10" :key="'skeletonloader-' + i"
                type="list-item"
                ref="skeleton"
                class="mx-auto my-0"
            ></v-skeleton-loader>
        </template>
    </v-card>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
            bottom: false,
            infinScroll: {
                index: 1,
                pageSize: 25,
                sort: 'DESC',
                loadingMore: false,
                maxReached: false
            },
            entriesLoaded: false,
            loadingEntries: false,
            headers: [
              { text: 'Type', align: 'start', sortable: false, value: 'thumb'},
              { text: 'Name', value: 'name', sortable: false},
              { text: 'Email', value: 'email', sortable: false},
              { text: 'Address', value: 'address', sortable: false},
              { text: 'Sample Date', value: 'sample_date', sortable: false},
              { text: 'Test Number', value: 'test_number', sortable: false},
              { text: 'Sample Result', value: 'sample_result', sortable: false},
              { text: 'Actions', value: 'actions', sortable: false },
            ]
        }
    },
    computed:{
        entriesLocal() {
            return this.$store.state.privateEntryFormData.entries.map((entry, index) => {
                return {
                    index: index,
                    thumb: 'account-box',
                    name: entry.firstName + ' ' + entry.lastName,
                    firstName: entry.firstName,
                    lastName: entry.lastName,
                    email: entry.email,
                    address: entry.address,
                    sample_date:  entry.sampleDate ? moment(entry.sampleDate, 'YYYY-MM-DD').format("DD-MMM-YYYY"): '',
                    test_number:  entry.testNumber,
                    sample_result:  entry.sampleResult,
                    birthDay:  entry.birthDay,
                    actions: null,
                    animateIn: entry.animateIn ? true : false
                }
            });
        },
        userObject() {
            return this.$store.state.userObject;
        }
    },
    watch: {
        bottom(bottom) {
            if (bottom) {
                this.loadFormEntries();
            }
        }
    },
    created() {
        this.loadFormEntries();
        window.addEventListener('scroll', this.bottomVisible);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.bottomVisible);
    },
    methods: {
        prefillPrivateEntryForm(item) {
            //create new object 
            const newFormData = {
                firstName: item.firstName,
                lastName: item.lastName,
                address: item.address,
                birthDay: item.birthDay,
                email: item.email,
            }

            this.$parent.formData = newFormData;
            this.$parent.showDialog = true;
        },
        bottomVisible() {
            const scrollY = window.scrollY;
            const visible = document.documentElement.clientHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const bottomOfPage = visible + scrollY >= pageHeight;
            this.bottom = bottomOfPage || pageHeight < visible;
        },
        loadFormEntries() {
            if(this.infinScroll.maxReached)
                return;
 
            this.loadingEntries = true;
            let payload = {
                pageNumber: this.infinScroll.index,
                pageSize: this.infinScroll.pageSize,
                sort: this.infinScroll.sort
            }
            if(this.infinScroll.index > 1)
                this.infinScroll.loadingMore= true;
                this.$store.dispatch('getPrivateEntryFormEntries', payload).then((res) => {
                    //loaders
                    this.loadingEntries = false;
                    if(this.infinScroll.loadingMore)
                        this.infinScroll.loadingMore = false;

                    //resutls
                    if(res && res.length == 0)
                        this.infinScroll.maxReached = true;
                    else if(res && res.length > 0)
                        this.infinScroll.index = this.infinScroll.index + 1
                })
        }
    }
}
</script>

<style lang="scss">

    //inset row in table
    .animate-in{
        animation-duration: 4s;
        animation-fill-mode: both;
        animation-name: insert-table;
    }

    @keyframes  insert-table {
        from {
            background-color: #bdffc0;
        }
        80% {
            background-color: #bdffc0;
        }
        100% {
            background-color: none;
        }
    }
</style>