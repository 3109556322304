
export class privateEntryFormDataKeys {
    constructor() {
        return {
            firstName: null,
            lastName: null,
            address: null,
            birthDay: null,
            email: null,
            testNumber: null,
            sampleDate: null,
            sampleResult: null
        }
    }
}
