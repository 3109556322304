<template>

  <div class="text-center">
    <v-dialog v-model="showDialog" width="800" scrollable persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark fab bottom right color="primary" fixed large v-bind="attrs" v-on="on" @click="resetForm()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card id="printMe">
        <v-card-title class="headline primary white--text" primary-title>Create Private Entry</v-card-title>
        
        <v-card-text>
            <v-form class="mt-8" style="max-width:450px;margin:0 auto;" ref="form-private-entry">
                <!-- first name -->
                <v-text-field
                    label="First Name"
                    type="text"
                    class="required"
                    :rules="[v => !!v || 'This field is required']" 
                    v-model="formData.firstName"
                ></v-text-field>

                <!-- last name -->
                <v-text-field
                    label="Last Name"
                    type="text"
                    class="required"
                    :rules="[v => !!v || 'This field is required']" 
                    v-model="formData.lastName"
                ></v-text-field>

                <!-- address -->
                <v-text-field
                    label="Address"
                    type="text"
                    class="required"
                    :rules="[v => !!v || 'This field is required']" 
                    v-model="formData.address"
                ></v-text-field>

                <!-- Birthday -->
                <v-dialog
                    ref="dialogBirthDay"
                    v-model="birthDayDialogOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            class="birthdaypicker-custom required"
                            :rules="[v => !!v || 'This field is required']"
                            :value="computedBirthDate"
                            required
                            append-icon="mdi-calendar"
                            clearable
                            label="Birthday"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        ref="picker"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1900-01-01"
                        @change="saveBirthDay($event)"
                        >
                    </v-date-picker>
                </v-dialog>

                <!-- email -->
                <v-text-field
                    label="Email"
                    type="email"
                    class="required"
                    :rules="emailRules"
                    v-model="formData.email"
                ></v-text-field>

                <!-- test number -->
                <v-text-field
                    label="Test Number"
                    type="text"
                    class="required"
                    :rules="[v => !!v || 'This field is required']" 
                    v-model="formData.testNumber"
                ></v-text-field>

                <!-- sample date -->
                <v-menu
                    v-model="sampleDateMenuOpen"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="computedSampleDate"
                        label="Sample Date"
                        readonly
                        class="required"
                        :rules="[v => !!v || 'This field is required']" 
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker @change="saveSampleDate($event)" @input="sampleDateMenuOpen = false"></v-date-picker>
                </v-menu>


                <!-- sample result -->
                <v-select
                    :items="['2019-nCoV Detected', 'Negative', 'Weakly Positive']"
                    label="Sample Result"
                    class="required"
                    :rules="[v => !!v || 'This field is required']" 
                    v-model="formData.sampleResult"
                ></v-select>
            </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showDialog = false">
                Cancel
            </v-btn>
            <v-btn color="success" :disabled="submittingForm" @click="submit()">
                Submit
                <v-icon right>mdi-send</v-icon>
            </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </div>
</template>


<script>
import moment from 'moment'
import { privateEntryFormDataKeys } from '@/views/PrivateEntryFormPage/data/private-entry-form-data.js'
import SharedFunctions from '@/mixins/SharedFunctions.vue'

export default {
    mixins: [SharedFunctions],
    props: ['formDataParent', 'showDialogParent'],
    data() {
        return {
            showDialog: false,
            formDataPrestine: JSON.stringify(new privateEntryFormDataKeys()),
            formData: new privateEntryFormDataKeys(),
            birthDayDialogOpen: false,
            sampleDateMenuOpen: false,
            required: false,
            submittingForm: false,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                v => this.checkForDotEmail(v)
            ],
        }
    },
    watch: {
        birthDayDialogOpen (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        showDialogParent(val) {
            this.showDialog = val;
            if(this.formDataParent)
                this.prefillFormWithDataFromParent(this.formDataParent);
        },
        showDialog(val) {
            this.$parent.showDialog = val;
        }
    },
    computed: {
        computedBirthDate () {
            return this.formData.birthDay ? moment(this.formData.birthDay,'YYYY-MM-DD').format('D-MMMM-YYYY') : ''
        },
        computedSampleDate() {
            return this.formData.sampleDate ? moment(this.formData.sampleDate, 'YYYY-MM-DD').format('D-MMMM-YYYY') : ''
        }
    },
    created() {

    },
    methods: {
        saveBirthDay (value) {
            this.formData.birthDay = value + 'T13:00:00.000Z[UTC]';
            this.birthDayDialogOpen = false;
        },
        saveSampleDate (value) {
            this.formData.sampleDate = value + 'T01:00:00.000Z[UTC]';
        },
        resetForm() {
            if(this.$refs['form-private-entry'])
                this.$refs['form-private-entry'].reset()
            this.$parent.formData = null;
            this.formData = JSON.parse(this.formDataPrestine);
        },
        submit() {
            if(!this.$refs['form-private-entry'].validate()) 
                return;
            this.submittingForm = true;
            document.documentElement.scrollTop = 0;
            this.$store.commit('CLEAN_UP_ANIMATION_CLASSES_PRIVATE_ENTRY_FORM');
            this.$store.commit('SET_SHOW_LOADER', {action:true, text:'One moment...'}); 
            this.$store.dispatch('submitPrivateEntryForm', this.formData).then(data => {
                this.submittingForm = false;
                this.showDialog = false;
                this.$store.commit('SET_SHOW_LOADER', {action:false, text:null}); 
                if(data) {
                    this.addNewEntryToTable(data);
                    this.resetForm();
                    let payload = {
                        open: true,
                        text: 'Entry successfully created.',
                        color: 'success'
                    }
                    this.$store.commit('SET_SNACKBAR', payload )
                }
            })
        },
        addNewEntryToTable(newEntry) {
            this.$store.commit('ADD_ITEM_PRIVATE_ENTRY_FORM_ENTRIES', newEntry);
        },
        prefillFormWithDataFromParent(data) {
            if(this.$refs['form-private-entry'])
                this.$refs['form-private-entry'].reset()
                
            this.formData.firstName = data.firstName;
            this.formData.lastName = data.lastName;
            this.formData.address = data.address;
            this.formData.birthDay = data.birthDay;
            this.formData.email = data.email;
        }
    }

}
</script>

<style>

</style>