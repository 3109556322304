<template>
    <v-container fluid v-if="hasAccessToPage">
        <!-- Entries table -->
        <v-row justify="center">
            <v-col cols="12" sm="12" md="10" lg="8">
                <PrivateEntryTable />
            </v-col>
        </v-row>
        <!-- Modal -->
        <PrivateEntryFormModal :showDialogParent="showDialog" :formDataParent="formData" />
    </v-container>
    <NoAccessMessage v-else />
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue'
import NoAccessMessage from '@/components/generic/no-access-message.vue'
import PrivateEntryTable from '@/views/PrivateEntryFormPage/components/PrivateEntryTable.vue'
import PrivateEntryFormModal from '@/views/PrivateEntryFormPage/components/PrivateEntryFormModal.vue'

export default {
    mixins: [SharedFunctions],
    components: {
        NoAccessMessage,
        PrivateEntryTable,
        PrivateEntryFormModal
    },
    data() {
        return {
            hasAccessToPage: false,
            showDialog: false,
            formData: null
        }
    },
    beforeDestroy() {
        this.$store.commit('RESET_PRIVATE_ENTRY_FORM_ENTRIES');
    },
    created() {
        this.hasAccessToPage = this.hasAccessToPageMixin();

    },

    methods: {

    }

}
</script>

<style>

</style>